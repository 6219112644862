(function () {
  'use strict';

  const classes = {
    headerNoStage: 'header__no-stage',
    hamburgerContentMenuNoStage: 'header__hamburger-content--no-stage'
  }

  const selectors = {
    stage: '.stage',
    stageWrapper: '.stage__wrapper',
    hamburgerContentMenu: '.header__hamburger-content',
    header: '.header',
  };

  const init = () => {

    const stage = document.querySelector(selectors.stage)
    const header = document.querySelector(selectors.header)
    const hamburgerContentMenu = document.querySelector(selectors.hamburgerContentMenu)

    if (stage && header && hamburgerContentMenu) {
      if (!stage.querySelector(selectors.stageWrapper)) {
        header.classList.add(classes.headerNoStage)
        hamburgerContentMenu.classList.add(classes.hamburgerContentMenuNoStage)
      }
    }

  };

  window.addEventListener('load', init);
})();
