(function () {
  'use strict';

  const classes = {
    wpformsError: 'wpforms-error',
    contactRequiredContainer: 'contact__required__container'
  };

  const selectors = {
    inputRequired: '.wpforms-field-required',
    contactRequiredContainer: '.' + classes.contactRequiredContainer
  };

  const ASTERISK = '*';

  const moveErrorMessageOutside = input => {
    const observer = new MutationObserver(mutationsList => {
      mutationsList.forEach(mutation => {
        mutation.addedNodes.forEach(node => {
          if ((node.tagName === 'EM' || node.tagName === 'LABEL') && node.classList.contains(classes.wpformsError)) {
            const containerDiv = input.closest(selectors.contactRequiredContainer);
            containerDiv.insertAdjacentElement('afterend', node);
          }
        });
      });
    });

    const containerDiv = input.closest(selectors.contactRequiredContainer);
    observer.observe(containerDiv, { childList: true });
  };

  const createAsterisk = (input, placeHolderText) => {
    const placeholder = document.createElement('span');
    placeholder.classList.add('contact__placeholder--required');
    if (input.type === 'tel') {
      placeholder.classList.add('contact__placeholder--required--tel');
    }
    placeholder.innerHTML = `${placeHolderText}<span class="contact__asterisk">*</span>`;
    return placeholder;
  };

  const manipulateElementsForPlaceholder = (input, placeholderText) => {
    const wrapper = document.createElement('div');
    wrapper.classList.add(classes.contactRequiredContainer);

    if (input.type === 'tel') {
      const itiContainer = input.closest('.iti');
      if (itiContainer) {
        itiContainer.parentNode.insertBefore(wrapper, itiContainer);
        wrapper.appendChild(itiContainer);
      }
    } else {
      input.parentNode.insertBefore(wrapper, input);
      wrapper.appendChild(input);
    }

    const placeHolderElement = createAsterisk(input, placeholderText);
    wrapper.appendChild(placeHolderElement);
    return placeHolderElement;
  };

  const handlePlaceholder = (evt, placeholder) => {
    if (evt.type === 'focus') {
      placeholder.style.display = 'none';
    } else if (evt.type === 'blur' || evt.type === 'input') {
      placeholder.style.display = evt.target.value.trim().length === 0 ? 'block' : 'none';
    }
  };

  /**
   * Permet d'attribuer la logique relative au placeholder dans l'input seulement
   * sur les formulaires de contact souhaité comme le fast contact ou le contact classique.
   * @param formSelector
   */
  const addEventsToTheInputOfTheRightforms = (formSelector) => {
    const form = document.querySelector(formSelector);
    if (form instanceof HTMLElement) {
      const inputs = form.querySelectorAll(selectors.inputRequired);
      inputs.forEach(input => {
        if (input instanceof HTMLElement) {
          const placeholder = input.getAttribute('placeholder');
          if (placeholder && placeholder.includes(ASTERISK)) {
            const placeHolderWithoutAsterisk = placeholder.replace(ASTERISK, '').trim();

            const placeholderElement = manipulateElementsForPlaceholder(input, placeHolderWithoutAsterisk);
            input.addEventListener('input', evt => handlePlaceholder(evt, placeholderElement));
            input.addEventListener('focus', evt => handlePlaceholder(evt, placeholderElement));
            input.addEventListener('blur', evt => handlePlaceholder(evt, placeholderElement));

            moveErrorMessageOutside(input);
          }
        }
      });
    }
  };

  const init = () => {
    addEventsToTheInputOfTheRightforms('.contact__form');
    addEventsToTheInputOfTheRightforms('.fast-contact__form-container');
  };

  document.addEventListener('WPFormsSmartPhoneFieldInitialized', init);
})();
