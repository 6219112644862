import Splide from '@splidejs/splide';

(function () {
    'use strict';

    const classes = {
        galleryDescriptionContentActive: 'gallery__description__content--active',
        galleryDescriptionContentInactive: 'gallery__description__content--hidden',
        galleryNavigationTitleBold: 'gallery__navigation__title--bold',
        galleryNavigationTitleClean: 'gallery__navigation__title--clean',
        galleryNavigationImageActive: 'gallery__image__container--active',
        galleryImageContainer: 'gallery__image__container',
        galleryImageSuperline: 'gallery__image__superline',
        galleryImageSuperlineActive: 'gallery__image__superline--active',
        galleryImageActive: 'gallery--image--active',
    }

    const selectors = {
        splide: '.js-gallery',
        pagination: '.js-pagination',
        galleryDescriptionContent: '.gallery__description__content',
        galleryDescriptionContentActive: '.gallery__description__content--active',
        galleryDescriptionContentInactive: '.gallery__description__content--hidden',
        galleryNavigationTitle: '.gallery__navigation__title',
        galleryImageContainer: '.' + classes.galleryImageContainer,
        galleryImageSuperline: '.' + classes.galleryImageSuperline,
        galleryImage: '.gallery--image',
        gallery: '.gallery',
        galleryWrapperDescription: '.gallery__wrapper--description',
    };

  const onClickUpdateDescriptionContent = (galleryIndex, index) => {
    document.querySelectorAll(`${selectors.galleryDescriptionContent}[data-gallery-index="${galleryIndex}"]`).forEach((desc) => {
      desc.classList.add(classes.galleryDescriptionContentInactive);
      desc.classList.remove(classes.galleryDescriptionContentActive);
    });

    const activeDescription = document.querySelector(`${selectors.galleryDescriptionContent}[data-gallery-index="${galleryIndex}"][data-index="${index}"]`);
    if (activeDescription) {
      activeDescription.classList.remove(classes.galleryDescriptionContentInactive);
      activeDescription.classList.add(classes.galleryDescriptionContentActive);
    }
  };

    const updateActiveTitle = (paginationSplide) => {
        const slides = paginationSplide.Components.Elements.slides;

        slides.forEach((slide, index) => {
            const titleElement = slide.querySelector(selectors.galleryNavigationTitle);
            if (titleElement) {
                titleElement.classList.remove(classes.galleryNavigationTitleBold);
            }

            if (index === paginationSplide.index && titleElement) {
                titleElement.classList.add(classes.galleryNavigationTitleBold);
                slide.classList.add(classes.galleryNavigationTitleClean)
            }
        });
    };

    const updateActiveImage = (mainSplide) => {
      const slides = mainSplide.Components.Elements.slides;

      slides.forEach((slide, index) => {
        const galleryImageContainerEl = slide.querySelector(selectors.galleryImageContainer);
        const galleryImageSuperline = slide.querySelector(selectors.galleryImageSuperline);

        galleryImageContainerEl.classList.remove(classes.galleryNavigationImageActive);
        galleryImageSuperline.classList.remove(classes.galleryImageSuperlineActive);
          if (mainSplide.index === index && slide) {
            galleryImageContainerEl.classList.add(classes.galleryNavigationImageActive);
            galleryImageSuperline.classList.add(classes.galleryImageSuperlineActive);
          }
      })
    }

  const toggleBeforeAfter = (mainSplide, action) => {
    const track = mainSplide.Components.Elements.track;
    if (track) {
      if (action === 'hide') {
        track.classList.remove(classes.galleryImageActive);
      } else if (action === 'show') {
        track.classList.add(classes.galleryImageActive);
      }
    }
  };

    const init = () => {

        const splides = document.querySelectorAll(selectors.splide);
        const splidesPagination = document.querySelectorAll(selectors.pagination);
        const mainSplides = [];
        const paginationSplides = [];
        splides.forEach((splide, index) => {

            if (splide instanceof HTMLElement) {

                const mainSplide = new Splide(splide, {
                    type: 'loop',
                    perPage: 1.5,
                    width: '100%',
                    height: '100%',
                    gap: '2rem',
                    focus: 'center',
                    pagination: false,
                    arrows: false,
                    breakpoints: {
                      480: {
                        perPage: 1
                      },
                      1024: {
                        perPage: 1
                      }
                    },
                });

                mainSplides.push(mainSplide);
            }


            const sp = splidesPagination[index]
            if (sp instanceof HTMLElement) {
                const paginationSplide = new Splide(sp, {
                    type: 'loop',
                    perPage: 1,
                    width: '100%',
                    height: '100%',
                    focus: 'center',
                    isNavigation: true,
                    fixedWidth: 150,
                    fixedHeight: 64,
                    pagination: false,
                    gap: '6em',
                    breakpoints: {
                        480: {
                            fixedWidth: '75',
                            fixedHeight: '64',
                            gap: '2em'
                        },
                        1024: {
                            gap: '3em'
                        }
                    },
                    classes: {
                        arrow: 'splide__arrow gallery__arrow',
                        prev: 'splide__arrow gallery__arrow--prev',
                        next: 'splide__arrow gallery__arrow--next',
                    }
                });
                paginationSplides.push(paginationSplide);

              const descriptionWrapper = splide.closest(selectors.gallery).querySelector(selectors.galleryWrapperDescription);
              if (descriptionWrapper) {
                const descriptions = descriptionWrapper.querySelectorAll(selectors.galleryDescriptionContent);
                descriptions.forEach((desc) => {
                  desc.setAttribute('data-gallery-index', index.toString());
                });
              }
            }
        });

        mainSplides.forEach((mainSplide, index) => {
            const paginationSplide = paginationSplides[index];
            if (paginationSplide) {
                mainSplide.sync(paginationSplide)
            }

            mainSplide.mount();
            if (paginationSplide) {
                paginationSplide.mount();

              mainSplide.on('move', () => {
                toggleBeforeAfter(mainSplide, 'hide');
              });

              mainSplide.on('moved', () => {
                toggleBeforeAfter(mainSplide, 'show');
              });

              mainSplide.on('drag', () => {
                toggleBeforeAfter(mainSplide, 'hide');
              });

              mainSplide.on('dragged', () => {
                toggleBeforeAfter(mainSplide, 'show');
              });

                paginationSplide.on('moved', () => {
                    const activeIndex = paginationSplide.index;
                    onClickUpdateDescriptionContent(index, activeIndex);
                    updateActiveTitle(paginationSplide);
                    updateActiveImage(mainSplide);
                });

            }
            // Mettre le bold la toute première fois.
            updateActiveTitle(paginationSplide);
            updateActiveImage(mainSplide);


        })

    };

    window.addEventListener('load', init);
})();
