(function () {
  'use strict';

  const classes = {
    formContainerActive: 'fast-contact__form-container--active'
  };

  const selectors = {
    fastContactButton: '.fast-contact__button__container',
    formContainer: '.fast-contact__form-container',
    wpForm: '.wpforms-form',
    confirmationMessageWpFormContainer: '.wpforms-confirmation-container-full'
  };

  const onClickFormVisibility = () => {
    const formContainer = document.querySelector(selectors.formContainer);
    if (formContainer) {
      formContainer.classList.toggle(classes.formContainerActive);
    }
  };

  const init = () => {
    const fastContactMenu = document.querySelector(selectors.fastContactButton);

    if (fastContactMenu instanceof HTMLElement) {
      fastContactMenu.addEventListener('click', onClickFormVisibility);
    }
  };

  window.addEventListener('load', init);
}
)
();
