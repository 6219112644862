(function () {
  'use strict';

  const classes = {
    hamburgerActive: 'header__hamburger--active',
    hamburgerMenuActive: 'header__hamburger-content--active',
    hamburgerMenuSticky: 'header__hamburger-content--sticky',
    headerSticky: 'header--sticky'
  };

  const selectors = {
    burger: '.js-burger-nav',
    header: '.header',
    main: '.main',
    stage: '.stage',
    imageWrapper: '.stage__image-wrapper',
    hamburgerContentMenu: '.header__hamburger-content',
    navBurgerLink: '.header__hamburger__link',
    section: 'section'
  };

  let hamburger, menuContent, navLink, header;

  const onClickBurger = () => {
    hamburger.classList.toggle(classes.hamburgerActive);
    menuContent.classList.toggle(classes.hamburgerMenuActive);
  };
  const onCloseMenuWhenLinkIsClicked = () => {
    menuContent.classList.remove(classes.hamburgerMenuActive);
  };

  const analyseStickyPosition = () => {
    const imageWrapper = document.querySelector(selectors.imageWrapper);
    const firstSection = document.querySelector(selectors.section);

    if (imageWrapper) {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (!entry.isIntersecting) {
              header.classList.add(classes.headerSticky);
              menuContent.classList.add(classes.hamburgerMenuSticky);
            } else {
              header.classList.remove(classes.headerSticky);
              menuContent.classList.remove(classes.hamburgerMenuSticky);
            }
          });
        },
        { threshold: [0] }
      );

      observer.observe(imageWrapper);
    } else if (firstSection) {
      const observer = new IntersectionObserver(
        entries => {
          entries.forEach(entry => {
            if (!entry.isIntersecting) {
              header.classList.add(classes.headerSticky);
              menuContent.classList.add(classes.hamburgerMenuSticky);
            } else {
              header.classList.remove(classes.headerSticky);
              menuContent.classList.remove(classes.hamburgerMenuSticky);
            }
          });
        },
        { threshold: [0.75] }
      );

      observer.observe(firstSection);
    }
  };

  const init = () => {
    hamburger = document.querySelector(selectors.burger);
    menuContent = document.querySelector(selectors.hamburgerContentMenu);
    navLink = document.querySelectorAll(selectors.navBurgerLink);
    header = document.querySelector(selectors.header);

    if (hamburger instanceof HTMLElement) {
      hamburger.addEventListener('click', onClickBurger);
    }

    navLink.forEach(n => {
      if (n instanceof HTMLElement) {
        n.addEventListener('click', onCloseMenuWhenLinkIsClicked);
      }
    });

    analyseStickyPosition();
  };

  window.addEventListener('load', init);
})();
