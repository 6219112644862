import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    splide: '.js-stage'
  };

  const init = () => {
    const splides = document.querySelectorAll(selectors.splide);
    splides.forEach((splide) => {
      if (splide instanceof HTMLElement) {
        new Splide(selectors.splide, {
          type: 'loop',
          perPage: 1,
          interval: 2000,
          speed: 500,
          width: 'auto',
          height: 'auto',
          arrows: false,
          pagination: true,
          classes: {
            pagination: 'splide__pagination stage-big__slider__pagination',
            page: 'splide__pagination__page stage-big__slider__pagination-page'
          }
        }).mount();
      }
    });
  };

  window.addEventListener('load', init);
})();
