import Splide from '@splidejs/splide';

(function () {
  'use strict';

  const selectors = {
    splide: '.js-gallery-reviews',
  };

  const init = () => {

    const splides = document.querySelectorAll(selectors.splide);
    splides.forEach((splide, index) => {

      if (splide instanceof HTMLElement) {
        const mainSplide = new Splide(splide, {
          type: 'slide',
          perPage: 3,
          gap: '30px',
          breakpoints: {
            1024: {
              perPage: 2,
            },
            480: {
              perPage: 1,
            }
          },
          pagination: false,
          arrows: true,
          classes: {
            arrow: 'splide__arrow testimonial__arrow',
            prev: 'splide__arrow testimonial__arrow--prev',
            next: 'splide__arrow testimonial__arrow--next',
          }
        });
        mainSplide.mount();
      }
    });
  };

  window.addEventListener('load', init);
})();
