(function () {
  'use strict';

  const classes = {
    cardActive: 'simulator__licence__card--active',
    cardInactive: 'simulator__licence__card--inactive',
    buttonClick: 'simulator__btn--click',
    inputUsers: 'js-input-users',
    inputDossiers: 'js-input-dossiers'
  };

  const selectors = {
    containerInput: '.simulator__input__control',
    input: '.js-input-number',
    btnIncrement: '.js-button-plus',
    btnDecrement: '.js-button-minus',
    cards: '.js-card',
    cardPrice: '.simulator__card__price',
    inputUsers: '.js-input-users',
    inputDossiers: '.js-input-dossiers',
    simulator: '.simulator',
    magistratBtnYes: '.simulator__btn--first',
    magistratBtnNo: '.simulator__btn--second',
    cta: '.simulator__card__cta'
  };

  const parameters = {
    dossierCost: 48,
    userCost: 240,
    maintenanceCost: 1200,
    maxPrice: 60000
  };

  const STEP_FOLDERS = 5;
  const STEP_USERS = 1;
  const MIN_VALUE = 1;

  let typingTimer;
  const typingInterval = 500;

  let isMagistrat = false;

  const verifyCardsToDisplay = (total, simulator) => {
    const cards = simulator.querySelectorAll(selectors.cards);
    let cardDisplayedNotMagistrat = false;

    cards.forEach((card, index) => {
      const cardPriceElement = card.querySelector(selectors.cardPrice);
      const maxPriceForCard = parseInt(card.dataset.maximumPrice);
      const nextCard = cards[index + 1];
      const nextCardPrice = nextCard && nextCard.dataset.magistrat === '0' ? parseInt(nextCard.dataset.maximumPrice) : parameters.maxPrice;
      const isMagistratCard = card.dataset.magistrat === '1';

      let shouldDisplay = false;
      if (isMagistrat) {
        shouldDisplay = isMagistratCard;
      } else if (!cardDisplayedNotMagistrat) {
        shouldDisplay = total <= maxPriceForCard && total <= nextCardPrice;
      }

      if (shouldDisplay) {
        cardDisplayedNotMagistrat = true;
        cardPriceElement.innerHTML = `${total} <span class="simulator__card__currency">€</span><span class="simulator__card__annual"> / an</span>`;
      }

      card.classList.toggle(classes.cardActive, shouldDisplay);
      card.classList.toggle(classes.cardInactive, !shouldDisplay);
    });
  };

  const calculateTotal = (simulator) => {
    const nbUsers = parseInt(simulator.querySelector(selectors.inputUsers).value) || MIN_VALUE;
    const nbDossiers = parseInt(simulator.querySelector(selectors.inputDossiers).value) || MIN_VALUE;

    let dossierTotal = 0;
    let userTotal = 0;
    const effectiveDossiers = nbDossiers > 5 ? nextMultipleOf5(nbDossiers) : nbDossiers;
    const effectiveUsers = nbUsers > 1 || nbDossiers > 5 ? nbUsers : 0;

    if (effectiveDossiers > 5 || effectiveUsers > 1) {
      dossierTotal = effectiveDossiers * parameters.dossierCost;
      userTotal = effectiveUsers * parameters.userCost;
    }

    const maintenanceCost = isMagistrat ? 0 : parameters.maintenanceCost;

    const total = Math.min((dossierTotal + userTotal + maintenanceCost), parameters.maxPrice);

    verifyCardsToDisplay(total, simulator);
  };

  const nextMultipleOf5 = (num) => {
    return Math.ceil(num / STEP_FOLDERS) * STEP_FOLDERS;
  };

  const previousMultipleOf5 = (num) => {
    return Math.floor(num / STEP_FOLDERS) * STEP_FOLDERS;
  };

  const onClickIncrement = (event, simulator) => {
    const input = event.currentTarget.closest(selectors.containerInput).querySelector(selectors.input);
    const currentValue = parseInt(input.value) || 0;
    const maxValue = parseInt(input.max);
    const isUserInput = input.classList.contains(classes.inputUsers);
    input.value = Math.min((isUserInput ? currentValue + 1 : nextMultipleOf5(currentValue + 1)), maxValue);
    input.dataset.lastValidValue = input.value.toString();
    calculateTotal(simulator);
    updateCtaUrl(simulator);
  };
  const onClickDecrement = (event, simulator) => {
    const input = event.currentTarget.closest(selectors.containerInput).querySelector(selectors.input);
    const currentValue = parseInt(input.value) || 0;
    const isUserInput = input.classList.contains(classes.inputUsers);
    input.value = Math.max(MIN_VALUE, (isUserInput ? currentValue - 1 : previousMultipleOf5(currentValue - 1)));
    input.dataset.lastValidValue = input.value.toString();
    calculateTotal(simulator);
    updateCtaUrl(simulator);
  };

  const onInputBlur = (simulator) => {
    const inputUsers = simulator.querySelector(selectors.inputUsers);
    const inputDossiers = simulator.querySelector(selectors.inputDossiers);

    if (inputUsers.value === '') {
      inputUsers.value = inputUsers.dataset.lastValidValue || MIN_VALUE;
    }
    if (inputDossiers.value === '') {
      inputDossiers.value = inputDossiers.dataset.lastValidValue || MIN_VALUE;
    }

    calculateTotal(simulator);
  };

  const valideInputValue = (input, minValue, maxValue, simulator) => {
    let value = parseInt(input.value) || minValue;

    if (value < minValue) {
      value = minValue;
    } else if (value > maxValue) {
      value = maxValue;
    }

    input.value = value;

    input.dataset.lastValidValue = value.toString();
    updateCtaUrl(simulator);
  };

  const onInputChange = (simulator) => {
    clearTimeout(typingTimer);
    typingTimer = setTimeout(() => {
      const inputUsers = simulator.querySelector(selectors.inputUsers);
      const inputDossiers = simulator.querySelector(selectors.inputDossiers);

      if (inputUsers.value.trim() !== '') {
        const minValueUsers = parseInt(inputUsers.min);
        const maxValueUsers = parseInt(inputUsers.max);
        valideInputValue(inputUsers, minValueUsers, maxValueUsers, simulator);
      }

      if (inputDossiers.value.trim() !== '') {
        const minValueDossiers = parseInt(inputDossiers.min);
        const maxValueDossiers = parseInt(inputDossiers.max);
        valideInputValue(inputDossiers, minValueDossiers, maxValueDossiers, simulator);
      }

      calculateTotal(simulator);
    }, typingInterval);
  };

  const onClickHandleMagistratButton = (isMagistratClicked, simulator) => {
    const magistratYesBtn = simulator.querySelector(selectors.magistratBtnYes);
    const magistratNoBtn = simulator.querySelector(selectors.magistratBtnNo);

    if (isMagistratClicked) {
      magistratYesBtn.classList.add(classes.buttonClick);
      magistratNoBtn.classList.remove(classes.buttonClick);
    } else {
      magistratYesBtn.classList.remove(classes.buttonClick);
      magistratNoBtn.classList.add(classes.buttonClick);
    }

    isMagistrat = isMagistratClicked;
    calculateTotal(simulator);
  };

  // Méthode pour la gestion du bon de commande (hors simulateur)
  const updateCtaUrl = (simulator) => {
    const formIdToRedirect = simulator.getAttribute('data-form-id');
    const inputNbUsers = simulator.querySelector(selectors.inputUsers);
    const inputDossiers = simulator.querySelector(selectors.inputDossiers);
    const nbUsers = parseInt(inputNbUsers.dataset.lastValidValue || inputNbUsers.value);
    const nbDossiers = parseInt(inputDossiers.dataset.lastValidValue || inputDossiers.value);

    const allCtaCards = simulator.querySelectorAll(selectors.cta);

    allCtaCards.forEach((ctaElement) => {
      // Savoir si la redirection vers le bon de commande a été demandé ou non.
      const orderFormCtaValue = ctaElement.getAttribute('data-order-form');
      if (ctaElement instanceof HTMLElement && orderFormCtaValue && formIdToRedirect) {
        const browserLang = document.documentElement.getAttribute('lang');
        // Récupérer ou initialiser l'URL de base
        const baseUrl = ctaElement.dataset.baseUrl || ctaElement.getAttribute('href');
        ctaElement.dataset.baseUrl = baseUrl;
        // 42 -> id du nombre de dossiers
        // 43 -> id du nombre de users
        const newUrl = `${baseUrl}?wpf${formIdToRedirect}_42=${nbDossiers}&wpf${formIdToRedirect}_43=${nbUsers}`;
        ctaElement.setAttribute('href', newUrl);
        console.log(browserLang);
        console.log(formIdToRedirect);
      }
    });
  };

  const init = () => {
    const simulators = document.querySelectorAll(selectors.simulator);

    simulators.forEach((simulator) => {
      const magistratYesBtn = simulator.querySelector(selectors.magistratBtnYes);
      const magistratNoBtn = simulator.querySelector(selectors.magistratBtnNo);

      if (magistratYesBtn instanceof HTMLElement) {
        magistratYesBtn.addEventListener('click', () => onClickHandleMagistratButton(true, simulator));
      }

      if (magistratNoBtn instanceof HTMLElement) {
        magistratNoBtn.addEventListener('click', () => onClickHandleMagistratButton(false, simulator));
      }

      const inputControls = simulator.querySelectorAll(selectors.containerInput);

      inputControls.forEach((control) => {
        const input = control.querySelector(selectors.input);
        const btnDecrement = control.querySelector(selectors.btnDecrement);
        const btnIncrement = control.querySelector(selectors.btnIncrement);
        if (btnDecrement instanceof HTMLElement) {
          btnDecrement.addEventListener('click', (event) => onClickDecrement(event, simulator));
        }

        if (btnIncrement instanceof HTMLElement) {
          btnIncrement.addEventListener('click', (event) => onClickIncrement(event, simulator));
        }

        if (input instanceof HTMLElement) {
          input.addEventListener('input', () => onInputChange(simulator));
          input.addEventListener('blur', () => onInputBlur(simulator));
        }
      });

      // Logique pour le bon de commande
      updateCtaUrl(simulator);
      calculateTotal(simulator);
    });
  };

  window.addEventListener('load', init);
})();
