(function () {
  'use strict';

  const classes = {
    buttonActive: 'faq__question__btn--active',
    questionActive: 'faq__question--active'
  }

  const selectors = {
    faqItem: '.faq__item',
    faqDescriptionWrapper: '.faq__description__wrapper',
    faqQuestion: '.faq__question',
    faqQuestionButton: '.js-faq__question__btn'
  };

  const init = () => {
    const accordions = document.querySelectorAll(selectors.faqItem);

    const toggleAccordion = (accordion, index) => {
      const isOpen = accordion.querySelector(selectors.faqDescriptionWrapper).style.height === `${accordion.querySelector(selectors.faqDescriptionWrapper).scrollHeight}px`;

      accordions.forEach((a, i) => {
        if (a instanceof HTMLElement) {
          const content = a.querySelector(selectors.faqDescriptionWrapper);
          const icon = a.querySelector(selectors.faqQuestionButton);
          const question = a.querySelector(selectors.faqQuestion)

          content.style.height = i === index && !isOpen ? `${content.scrollHeight}px` : '0px';
          icon.classList.toggle(classes.buttonActive, i === index && !isOpen);
          question.classList.toggle(classes.questionActive, i === index && !isOpen);
        }
      });
    };

    accordions.forEach((accordion, index) => {
      if (accordion instanceof HTMLElement) {
        const header = accordion.querySelector(selectors.faqQuestion);
        const content = accordion.querySelector(selectors.faqDescriptionWrapper);

        content.addEventListener('click', () => toggleAccordion(accordion, index));
        header.addEventListener('click', () => toggleAccordion(accordion, index));
      }
    });
  };

  window.addEventListener('load', init);
})();
